import React, { useState } from "react";
import useSWR, { useSWRConfig } from "swr";
import classNames from "classnames";

import { centraPost } from "../lib/centra-api";
import {
  useAbortOnRouteChange,
  useCentraToken,
  useProductPrice,
} from "../lib/utils";
import Link, { LinkProps } from "../components/Link";
import * as ga from "../lib/ga";
import StoryblokImage from "./StoryblokImage";
import CentraProductImage from "./CentraProductImage";

type Props = {
  className?: string;
  coverMedia?: StoryblokAsset;
  forceAspectRatio?: boolean;
  productId: number | string;
  reverseImages?: boolean;
};

export default function ProductGridItem({
  className,
  coverMedia,
  forceAspectRatio,
  reverseImages,
  productId,
}: Props): React.ReactElement {
  const [token] = useCentraToken();
  const fetchController = useAbortOnRouteChange();
  const { mutate } = useSWRConfig();

  const { data, error } = useSWR<CentraProductApiData>(
    `https://monokel.centra.com/api/checkout/products/${productId}`,
    (url) => centraPost(url, token, "", fetchController.signal),
    { revalidateOnMount: true }
  );
  const price = useProductPrice(data?.product);

  // Add to bag
  const [addToBagLabel, setAddToBagLabel] = useState("カートに入れる");

  if (error) {
    return <></>;
  }

  if (!data) {
    return (
      <div className="Grid-block">
        <div className="Grid-blockGraphic Grid-blockGraphic--empty" />

        <div className="Grid-blockContent Grid-blockContent--product">
          <div className="Grid-productName">
            {/* Loading… */}
            ロード中…
          </div>
          <div className="Grid-productPrice">&nbsp;</div>
        </div>
      </div>
    );
  }

  const product = data.product;
  const frame_color = product.frame_color.replace("ECO", "").trim();

  const linkProps: LinkProps = {
    onClick: () => {
      // Push select item event to GA
      if (product) {
        ga.event({
          action: "select_item",
          params: {
            "x-fb-event_id": "select_item",
            items: [
              {
                item_id: product.sku,
                currency: "N/A",
                quantity: 1,
                item_name: product.name,
                affiliation: product.brandName,
                item_collection: product.collectionName,
                item_variant: product.variantName,
              },
            ],
          },
        });
      }
    },
    href: "/" + product.uri,
    prefetch: false,
  };

  async function addToBag() {
    setAddToBagLabel("追加中..."); // Adding
    const item = product.items[0];
    const data = await centraPost(
      `https://monokel.centra.com/api/checkout/items/${item.item}`,
      token,
      { quantity: 1 }
    );
    // Added
    setAddToBagLabel("追加しました");
    mutate("https://monokel.centra.com/api/checkout/selection", data, false);

    // // Push add to cart event to GA
    if (product) {
      ga.event({
        action: "add_to_cart",
        params: {
          "x-fb-event_id": "add_to_cart",
          items: [
            {
              item_id: product.sku,
              item_name: product.name,
              affiliation: product.brandName,
              item_collection: product.collectionName,
              item_variant: product.variantName,
              currency: data.selection.currency,
              quantity: 1,
              price: product.priceAsNumber,
            },
          ],
        },
      });
    }

    const showCartEvent = new CustomEvent("monokel.showcart");
    window.dispatchEvent(showCartEvent);
  }

  const firstIndex = reverseImages ? 1 : 0;
  const secondIndex = reverseImages ? 0 : 1;

  const firstMediaCentra = product.media?.["1000x0"]?.[firstIndex] ? (
    <CentraProductImage
      className={
        coverMedia?.id ? "Grid-blockGraphicHoverImg" : "Grid-blockGraphicImg"
      }
      src={product.media["1000x0"][firstIndex]}
      sizes="(min-width: 1024px) 25vw, 50vw"
    />
  ) : null;

  const secondMediaCentra = product.media?.["1000x0"]?.[secondIndex] ? (
    <CentraProductImage
      className={
        coverMedia?.id ? "Grid-blockGraphicImg" : "Grid-blockGraphicHoverImg"
      }
      src={product.media["1000x0"][secondIndex]}
      sizes="(min-width: 1024px) 25vw, 50vw"
    />
  ) : null;

  const firstMedia = coverMedia?.id ? (
    <StoryblokImage className="Grid-blockGraphicImg" asset={coverMedia} />
  ) : (
    firstMediaCentra
  );

  const secondMedia = coverMedia?.id ? firstMediaCentra : secondMediaCentra;

  function removeDecimals(number) {
    return Math.round(Number(number));
  }

  if (firstMedia && secondMedia) {
    return (
      <div className={classNames("Grid-block", className)}>
        <Link className="Grid-blockGraphic" {...linkProps}>
          {firstMedia}
          {secondMedia}
        </Link>

        <div
          className={classNames("Grid-blockContent", {
            "Grid-blockContent--product": !forceAspectRatio,
            "Grid-blockContent--overProductImage": forceAspectRatio,
          })}
        >
          <Link {...linkProps}>
            <h2 className="Grid-productName">
              {product.model} {frame_color}
            </h2>

            {product.available && price?.showAsOnSale ? (
              <div className="Grid-productPrice is-discounted">
                <del>{price?.priceBeforeDiscount}</del>
                <ins>{price?.price}</ins>
              </div>
            ) : null}

            {product.available && !price?.showAsOnSale ? (
              <div className="Grid-productPrice">{price?.price}</div>
            ) : null}

            {!product.available ? (
              <div className="Grid-productPrice is-soldOut">
                {/* Sold out */}
                売り切れ
              </div>
            ) : null}
          </Link>

          {product.available ? (
            <button
              className="Grid-productAdd"
              disabled={!product.available}
              onClick={addToBag}
            >
              {addToBagLabel}
            </button>
          ) : null}
        </div>
      </div>
    );
  } else return null;
}
