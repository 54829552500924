import React from "react";
import { fetchRetry } from "../lib/utils";

interface NewsletterFormProps {
  signUpError?: () => void;
  routeToConfirmationPage?: () => void;
  signUpSuccessfull?: () => void;
  placeholder: string;
}

const NewsletterForm = ({
  routeToConfirmationPage,
  signUpError,
  signUpSuccessfull,
  placeholder,
}: NewsletterFormProps) => {
  const registerNewSubscriber = async (event) => {
    event.preventDefault();
    const res = await fetchRetry("api/subscribe-api", {
      body: JSON.stringify({
        name: event.target.email.value,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });
    const result = await res.json();
    if (result.message === "Success") {
      if (routeToConfirmationPage) routeToConfirmationPage();
      if (signUpSuccessfull) signUpSuccessfull();
    } else {
      console.error(result.message);
      signUpError();
    }
  };

  return (
    <div className="Subscribe-formWrapper">
      <form className="Subscribe-form" onSubmit={registerNewSubscriber}>
        <input
          pattern="^(?![_.-])((?![_.-][_.-])[a-zA-Z\d_.-]){0,63}[a-zA-Z\d]@((?!-)((?!--)[a-zA-Z\d-]){0,63}[a-zA-Z\d]\.){1,2}([a-zA-Z]{2,14}\.)?[a-zA-Z]{2,14}$"
          className="Subscribe-input"
          name="email"
          id="email"
          type="email"
          autoComplete="email"
          required
          autoFocus
          placeholder={placeholder}
        />
        <button className="Subscribe-sendButton">
          {/* Send */}
          送信
        </button>
      </form>
    </div>
  );
};

export default NewsletterForm;
