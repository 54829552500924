import ProductGridItem from "./ProductGridItem";

type Props = {
  blok: {
    cover_media?: StoryblokAsset;
    product?: {
      productIds: object;
    };
    width?: string;
  };
};

export default function ProductBlock({ blok }: Props) {
  const productBlock = blok.product;
  const productIds = productBlock?.productIds;
  const coverMedia = blok?.cover_media;
  const width = blok.width;

  if (!productIds?.[0]) {
    return <></>;
  }

  return (
    <ProductGridItem
      className={width && width !== "regular" ? `Grid-block--${width}` : ""}
      coverMedia={coverMedia}
      productId={productIds[0]}
      forceAspectRatio
    />
  );
}
