import { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import useSWR, { useSWRConfig } from "swr";
import classNames from "classnames";
import { NextSeo } from "next-seo";

import { useCentraToken, useProductPrice } from "../lib/utils";
import { centraPost } from "../lib/centra-api";
import { StoryContext } from "../pages/[...slug]";
import * as ga from "../lib/ga";
import CentraProductImage from "./CentraProductImage";
import ProductGridItem from "./ProductGridItem";
import DynamicBlock from "./DynamicBlock";
import ZoomGallery from "./ZoomGallery";

type Props = {
  productId: number;
};

function ucfirst(str = "") {
  return str.slice(0, 1).toUpperCase() + str.slice(1);
}

export default function Product({ productId }: Props) {
  const context = useContext(StoryContext);
  const [token] = useCentraToken();
  const { mutate } = useSWRConfig();
  const { data } = useSWR<CentraProductApiData>(
    `https://monokel.centra.com/api/checkout/products/${productId}`,
    (uri) => centraPost(uri, token)
  );
  const price = useProductPrice(data?.product);
  const { asPath } = useRouter();
  const origin =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : "";
  const URL = `${origin}${asPath}`;

  const story = context.story;
  const product = data?.product;
  const productMedia = product.media["1000x0"]
    ? product.media["1000x0"].slice(0)
    : [];

  const [outOfStockFormStatus, setOutOfStockFormStatus] = useState("");
  const [outOfStockEmail, setOutOfStockEmail] = useState("");
  const [showZoomGallery, setShowZoomGallery] = useState(false);

  const [showOutOfStockForm, setShowOutOfStockForm] = useState(false);
  const [galleryImgs, setGalleryImgs] = useState<HTMLImageElement[]>([]);

  // If there's a fourth image, replace the second image with that one. Why
  // do we do this? Because this means that images 1, 2 and 3 can contain
  // predictable image motifs (useful when outputting the product catalog to
  // another format, a Google Merchant feed for instance) but the client can
  // still get flexibility around which images appear where on the product
  // page
  if (productMedia.length > 3) {
    productMedia.splice(1, 1, productMedia[3]);
  }

  // If there's a fifth image, replace the third image with that one
  if (productMedia.length > 4) {
    productMedia.splice(2, 1, productMedia[4]);
  }

  // Add to bag
  const [addToBagLabel, setAddToBagLabel] = useState("カートに入れる");

  async function addToBag() {
    // Adding...
    setAddToBagLabel("追加中...");
    const item = product.items[0];
    const data = await centraPost(
      `https://monokel.centra.com/api/checkout/items/${item.item}`,
      token,
      { quantity: 1 }
    );

    // Added
    setAddToBagLabel("追加しました");
    mutate("https://monokel.centra.com/api/checkout/selection", data, false);

    // Push add to cart event to GA
    if (product) {
      ga.event({
        action: "add_to_cart",
        params: {
          "x-fb-event_id": "add_to_cart",
          items: [
            {
              item_id: product.sku,
              currency: data.selection.currency,
              quantity: 1,
              item_name: product.name,
              affiliation: product.brandName,
              item_collection: product.collectionName,
              item_variant: product.variantName,

              price: product.priceAsNumber,
            },
          ],
        },
      });
    }

    const showCartEvent = new CustomEvent("monokel.showcart");
    window.dispatchEvent(showCartEvent);
  }

  async function registerStockNotification(event) {
    event.preventDefault();

    setOutOfStockFormStatus("submitting");

    setTimeout(async () => {
      try {
        const data = await centraPost(
          `https://monokel.centra.com/api/checkout/newsletter-subscription/${outOfStockEmail}`,
          token,
          {
            product: product.product,
          }
        );

        if (data.subscribed) {
          setOutOfStockFormStatus("success");
          setOutOfStockEmail("");
        }
      } catch (error) {
        console.log("Error submitting out of stock notice", error);
        setOutOfStockFormStatus("error");
      }
    }, 1000);
  }

  const openGallery = () => {
    window.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        e.preventDefault();
        closeGallery();
      }
    });

    document.body.classList.add("-disableScroll");
    setShowZoomGallery(true);
  };

  const closeGallery = () => {
    setShowZoomGallery(false);
    document.body.classList.remove("-disableScroll");
  };

  // Find images and set gallery images
  useEffect(() => {
    const imgs = document.querySelectorAll(".Product img, .SbImgs img");
    setGalleryImgs([...imgs] as HTMLImageElement[]);
  }, [product, story]);

  // Add gallery click events for all images
  useEffect(() => {
    galleryImgs.forEach((img, i) => {
      img.style.cursor = "zoom-in";
      const zoomId = document.getElementById(`zoom-${i}`);

      img.addEventListener("click", (e) => {
        e.preventDefault();
        openGallery();
        zoomId.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
      });
    });
  }, [galleryImgs]);

  const splitName = product.name.split(" - ");

  // Push view item event to GA on load
  useEffect(() => {
    if (product) {
      ga.event({
        action: "view_item",
        params: {
          "x-fb-event_id": "view_item",
          items: [
            {
              item_id: product.sku,
              currency: "N/A",
              quantity: 1,
              item_name: product.name,
              affiliation: product.brandName,
              item_collection: product.collectionName,
              item_variant: product.variantName,
              price: product.priceAsNumber,
            },
          ],
        },
      });
    }
  }, []);

  return (
    <>
      <NextSeo
        title={`${product.metaTitle || product.name} – Monokel Eyewear`}
        description={product.metaDescription}
        openGraph={{
          title: product.metaTitle || product.name,
          description: product.metaDescription,
          type: "product",
          url: URL,
          images: productMedia.map((image) => {
            return {
              url: image,
              width: 700,
              height: 1050,
              alt: product.metaTitle,
            };
          }),
        }}
      />

      <div
        className={classNames("Product", {
          "Product--flushBottom":
            story?.content.grid?.length || product.relatedProducts.length,
          "is-soldOut": !product.available,
        })}
      >
        <div className="Product-header">
          <h2 className="Product-headerName">
            {splitName[0]}
            <br />
            {ucfirst(splitName[1])}
          </h2>

          <div className="Product-headerRight">
            <div>
              {price?.showAsOnSale ? (
                <div className="Product-headerPrice is-discounted">
                  <del>{price?.priceBeforeDiscount}</del>
                  <ins>{price?.price}</ins>
                </div>
              ) : (
                <div className="Product-headerPrice">{price?.price}</div>
              )}

              <button
                className="Product-headerAdd"
                disabled={!product.available}
                onClick={addToBag}
              >
                {product.available ? addToBagLabel : "売り切れ"}
                {/* Sold out */}
              </button>
            </div>

            {!product.available && !showOutOfStockForm && (
              <button
                onClick={() => setShowOutOfStockForm(true)}
                className="Product-notifyButton"
              >
                <div className="Product-notifyLabel">
                  {/* Get notified when in stock */}
                  入荷お知らせメールを受信する
                </div>
              </button>
            )}
            {showOutOfStockForm && outOfStockFormStatus !== "success" ? (
              <>
                <form onSubmit={registerStockNotification}>
                  <div className="Product-outOfStock">
                    <label className={classNames("Product-label")}>
                      <span className="Checkout-labelText">
                        {/* Receive arrival notification email */}
                        [入荷お知らせメールを受信する]
                      </span>

                      <input
                        className="Product-outOfStockInput"
                        disabled={
                          outOfStockFormStatus === "submitting" ||
                          outOfStockFormStatus === "success"
                        }
                        name="email"
                        onChange={(event) =>
                          setOutOfStockEmail(event.target.value)
                        }
                        required
                        type="email"
                        value={outOfStockEmail}
                      />

                      <button className="Checkout-inlineButton">
                        <div>
                          {outOfStockFormStatus === "success"
                            ? "Thank you!"
                            : ""}
                        </div>
                        <div>
                          {outOfStockFormStatus === "submitting"
                            ? "ご登録ありがとうございます！"
                            : /* Thank you for your registration! */
                            ""}
                          {/* Submitting */}
                        </div>
                        <div>{outOfStockFormStatus === "" ? "登録" : ""}</div>
                        {/* Submit */}
                      </button>
                    </label>
                  </div>
                </form>
              </>
            ) : null}

            {outOfStockFormStatus === "error" && (
              <div className="Product-outOfStockResponse">
                {
                  "Something went wrong while submitting. Please try again later."
                }
              </div>
            )}
            {outOfStockFormStatus === "success" && (
              <div className="Product-outOfStockResponse">
                {"Thank you! We'll keep you posted."}
              </div>
            )}
          </div>
        </div>
        <figure className="Product-galleryImage Product-galleryImage--big">
          <CentraProductImage
            alt={product.brandName + " " + product.name}
            src={productMedia[0]}
            sizes="50vw"
          />
        </figure>

        <div className="Product-content">
          <div className="Product-contentColumn Product-contentColumn--first">
            {story.content.info_desc?.content ? (
              <div
                className="u-richText --widerHeading"
                dangerouslySetInnerHTML={{
                  __html: story.content.info_desc.content,
                }}
              />
            ) : null}
          </div>

          <div className="Product-contentColumn">
            {story.content.color_desc?.content ? (
              <div
                className="u-richText"
                dangerouslySetInnerHTML={{
                  __html: "<h3>Colour</h3>" + story.content.color_desc.content,
                }}
              />
            ) : null}

            {story.content.lens_desc?.content ? (
              <div
                className="u-richText"
                dangerouslySetInnerHTML={{
                  __html: "<h3>Lens</h3>" + story.content.lens_desc.content,
                }}
              />
            ) : null}
          </div>
          <div className="Product-contentColumn--images">
            {productMedia.slice(1, 3).map((image, i) => (
              <figure className="Product-galleryImage" key={image}>
                <CentraProductImage
                  alt={product.brandName + " " + product.name}
                  src={image}
                  sizes="50vw"
                />
              </figure>
            ))}
          </div>
        </div>
      </div>

      {story?.content.grid?.length ? (
        <div
          className={classNames("SbImgs Grid Grid--flushTop", {
            "Grid--flushBottom": product.relatedProducts.length,
          })}
        >
          {story.content.grid.map((blok) => (
            <DynamicBlock key={blok._uid} blok={blok} />
          ))}
        </div>
      ) : null}

      {galleryImgs.length && (
        <div
          className={classNames("Product-zoomGallery", {
            "Product-galleryVisible": showZoomGallery,
          })}
        >
          <ZoomGallery onClick={closeGallery} images={galleryImgs} />
        </div>
      )}

      {product.relatedProducts.length ? (
        <div className={classNames("Grid Grid--flushTop", {})}>
          <div className="Grid-block Grid-block--full">
            <h3 className="Grid-blockHeading">
              {/* Related products */}
              関連商品
            </h3>
          </div>

          {product.relatedProducts.map((p) => (
            <ProductGridItem productId={p.product} key={p.product} />
          ))}
        </div>
      ) : null}
    </>
  );
}
