import { createContext } from "react";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";

import DynamicComponent from "../components/DynamicBlock";
import Product from "../components/PageProduct";
import Header from "../components/Header";
import Footer from "../components/Footer";

import { centraPostWithAuthAndCache } from "../lib/centra-api";
import { useStoryblok } from "../lib/storyblok";
import {
  getStoryblokDataSources,
  getStoryblokStory,
} from "../lib/storyblok-api";

export const StoryContext = createContext(null);

export const StoryProvider = ({ children, ...props }) => {
  const router = useRouter();
  const isWorkingInStoryblok = Boolean(
    props.preview && router.query._storyblok
  );

  return (
    <StoryContext.Provider value={{ ...props, preview: isWorkingInStoryblok }}>
      {children}
    </StoryContext.Provider>
  );
};

export default function Slug({
  productId,
  type,
  story: storyProp,
  mainMenu: mainMenuProp,
  preview,
  isEditingMainMenu,
  ...props
}) {
  const story = !isEditingMainMenu
    ? useStoryblok(storyProp, preview)
    : storyProp;

  const mainMenu = isEditingMainMenu
    ? useStoryblok(mainMenuProp, preview)
    : mainMenuProp;

  if (type === "story") {
    return (
      <StoryProvider {...props} mainMenu={mainMenu} story={story}>
        <NextSeo title={story?.name} />
        <Header />
        <DynamicComponent blok={story.content} />
        <Footer />
      </StoryProvider>
    );
  }

  return (
    <StoryProvider {...props} mainMenu={mainMenu} story={story}>
      <Header />
      <Product productId={productId} />
      <Footer />
    </StoryProvider>
  );
}

export async function getStaticProps({ params, preview }) {
  let slug = params?.slug ? params.slug.join("/") : "home";
  const isWorkingInStoryblok = process.env.NODE_ENV !== "production" || preview;

  let isEditingMainMenu = false;

  if (slug === "main-menu") {
    slug = "home";
    isEditingMainMenu = true;
  }

  const centraRes = centraPostWithAuthAndCache(
    "https://monokel.centra.com/api/checkout/products",
    "none",
    {
      uri: { uri: slug, for: ["product"] },
      limit: 1,
      market: "6",
    }
  ).catch(() => {
    // Do nothing
  });

  const centraProductsRes = centraPostWithAuthAndCache(
    "https://monokel.centra.com/api/checkout/products/",
    "none",
    {
      market: "6",
    }
  );

  const storyRes = getStoryblokStory(slug, {
    version: isWorkingInStoryblok ? "draft" : "published",
  });

  const mainMenuRes = getStoryblokStory("main-menu", {
    version: isWorkingInStoryblok ? "draft" : "published",
  });

  const dataSourcesRes = getStoryblokDataSources();

  try {
    // eslint-disable-next-line no-var
    var [centraData, centraProductsData, dataSources, story, mainMenu] =
      await Promise.all([
        centraRes,
        centraProductsRes,
        dataSourcesRes,
        storyRes,
        mainMenuRes,
      ]);
  } catch (error) {
    console.error(error);

    return {
      notFound: true,
    };
  }

  const swrFallback = centraProductsData.products.reduce((result, p) => {
    const url = `https://monokel.centra.com/api/checkout/products/${p.product}`;
    result[url] = { product: p };

    return result;
  }, {});

  if (centraData?.productCount) {
    const centraProduct = centraData.products[0];
    const props: any = {
      type: "product",
      productId: centraProduct.product,
      dataSources,
      mainMenu,
      swrFallback,
      preview: Boolean(preview),
      isEditingMainMenu,
    };

    if (story?.content.component === "product-page") {
      props.story = story;
    }

    return {
      revalidate: 10,
      props,
    };
  }

  if (story) {
    return {
      revalidate: 10,
      props: {
        type: "story",
        story,
        dataSources,
        mainMenu,
        swrFallback,
        preview: Boolean(preview),
        isEditingMainMenu,
      },
    };
  }

  return {
    notFound: true,
  };
}

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: "blocking",
  };
}
