import { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import imagesLoaded from "imagesloaded";

const imageWidths = [
  ["700", "700x0"],
  ["1000", "1000x0"],
  ["1350", "1350x0"],
];

type Props = {
  alt?: string;
  className?: string;
  height?: string | number;
  onLoad?(width: number, height: number): void;
  sizes?: string;
  src: string;
  width?: string | number;
};

export default function CentraProductImage({
  alt = "",
  className = "",
  height: heightProp = 1500,
  onLoad,
  sizes,
  src,
  width: widthProp = 1000,
}: Props) {
  // Why do we use a ref to store `isLoaded` state instead of normal React
  // state? Because normal state can lead to "stale closures", which sometimes
  // causes very janky behavior where the onLoad function is triggered
  // endlessly even though `isLoaded` is supposed to be true
  const isLoadedRef = useRef<{ isLoaded: boolean }>();
  const imgRef = useRef<HTMLImageElement>();
  const [width, setWidth] = useState(heightProp);
  const [height, setHeight] = useState(widthProp);

  useEffect(() => {
    if (!imgRef.current) {
      return;
    }

    imagesLoaded(imgRef.current, () => {
      isLoadedRef.current = { isLoaded: true };

      if (!imgRef.current) {
        return;
      }

      setWidth(imgRef.current.naturalWidth);
      setHeight(imgRef.current.naturalHeight);
      onLoad?.(imgRef.current.naturalWidth, imgRef.current.naturalHeight);
    });
  }, [imgRef.current]);

  const srcset = imageWidths.map(([w, name]) => {
    const url = src.replace(/-1000x0\.(\w+)$/, `-${name}.$1`);
    return `${url} ${w}w`;
  });

  return (
    <img
      className={classNames(className, {
        "is-loaded": isLoadedRef.current?.isLoaded,
      })}
      src={src}
      srcSet={srcset.join(", ")}
      sizes={sizes}
      alt={alt}
      height={height}
      width={width}
      ref={imgRef}
      loading="lazy"
    />
  );
}
